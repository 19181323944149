(function ($) {
  $(function() {
    $(".video").click(function () {
      $(this).find(".play-button").hide();
      $(this).find(".field-name-field-video-poster").hide();
      $(this).find("iframe").attr("src", setAutoPlay($(this).find("iframe").attr("src")));
      //turn off this handler for the video that is now playing as it is no longer needed
      $(this).off("click");
    });

    $(".hof-video, .lib-video").click(function () {
      var overlay = $(this).find(".video-overlay");
      if (overlay.hasClass("video-overlay-hidden")) {
        overlay.removeClass("video-overlay-hidden");
        overlay.find("iframe").attr("src", setAutoPlay(overlay.data("video")));
      } else {
        overlay.find("iframe").attr("src", "");
        overlay.addClass("video-overlay-hidden");
      }
    });

    $(".video-link").click(function(e){
      //don't go to video page.  Instead open a pop-up bax here.
      e.preventDefault();
      var overlay = $(".video-popup").find(".video-overlay");
      overlay.removeClass("video-overlay-hidden");
      overlay.find("iframe").first().attr({"src": setAutoPlay($(this).attr("href")), "allow": "autoplay; fullscreen; picture-in-picture"});
    });

    $("body").on("click", ".video-close", function(){
      var overlay = $(this).closest(".video-overlay");
      overlay.find("iframe").attr("src", "");
      overlay.addClass("video-overlay-hidden");
    });
  });
}(jQuery));

function setAutoPlay(src){
  src += src.includes("?")? "&": "?";
  src += "autoplay=1&title=0&byline=0&portrait=0";
  return src;
}
