(function ($) {

  $(function() {
    var tabsActiveClass = 'is-active';

    //if hash wasn't present set it to the default for the page root
    if(!window.location.hash) {
      switch(window.location.pathname){
        case '/hall-of-fame':
          window.location.hash = '#horse';
          break;
        case '/exhibits':
          window.location.hash = '#special';
          break;
        case '/education':
          window.location.hash = '#adult';
          break;
      }
    }

    //if the page has a hash value then activate the necessary tabs
    $(window).on('hashchange',function(){
      //reset all tab links to unselected
      $('.tabs-title').removeClass(tabsActiveClass);
      $('.tabs-title').find('a').attr('aria-selected', false);
      $('.tabs-panel').removeClass(tabsActiveClass);
      //now set the needed tab to selected.
      $('a[href="'+ window.location.hash +'"]').attr('aria-selected', true);
      $('a[href="'+ window.location.hash +'"]').parent().addClass(tabsActiveClass);
      $(window.location.hash).addClass(tabsActiveClass);
      $("html, body").animate({ scrollTop: 0 }, 0);
    });

    if (window.location.hash)
      $(window).trigger('hashchange');

    //use this to wrap tab panels when present
    $('div.tabs-panel').wrapAll('<div class="tabs-content" data-tabs-content="' + $('.page-tabs').attr('id') + '"></div>');
  });
}(jQuery));
