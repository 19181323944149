// Overwrites old selector
jQuery.expr[':'].contains = function(a, i, m) {
  return jQuery(a).text().toUpperCase()
    .indexOf(m[3].toUpperCase()) >= 0;
};


(function ($) {

  $(function() {
    if (window.location.pathname == '/hall-of-fame'){
      var search = $('<select name="searchType"/>');
      search.append(new Option('HOF Inductee Name', 'name'));
      search.append(new Option('Induction Year', 'year'));
      var input = $('<input type="text" name="searchValue" size="30" style="height:26px;"/>');
      var searchbutton = $('<button id="hofSearchButton">Search</button>');
      var clearButton = $('<button id="hofClearButton">Clear</button>');
      var hofSearchDiv = $('<div class="hofSearch center"/>');
      hofSearchDiv.append('<h4>Search for</h4> ').append(search).append(' ').append(input).append(' ').append(searchbutton).append(' ').append(clearButton);

      hofSearchDiv.insertAfter('.pane-title');
    }

    $(document).on('click', '#hofClearButton' , function() {
      $('[name=searchValue]').val('');
      $('div.tabs-content .is-active .row .gallery').show();
    });

    $(document).on('click', '#hofSearchButton' , function() {
      //make sure all are showing in case search has been preformed before
      $('div.tabs-content .is-active .row .gallery').show();
      //based on the type of search hide the blocks that don't match the search criteria
      if ($('[name=searchType]').val() == 'name')
        $('div.tabs-content .is-active .row .gallery .block-title a:not(:contains("'+ $('[name=searchValue]').val() +'"))').parent().parent().hide();
      else
        $('div.tabs-content .is-active .row .gallery .year-inducted:not(:contains('+ $('[name=searchValue]').val() +'))').parent().hide();
    });
  });

}(jQuery));
