(function ($) {
  //open the navigation items with external links in new tabs
  $(function() {
    $("ul.menu li a[href^='http://']").attr("target","_blank");
    $("ul.menu li a[href^='https://']").attr("target","_blank");
    $("ul.footer-menu li a[href^='http://']").attr("target","_blank");
    $("ul.footer-menu li a[href^='https://']").attr("target","_blank");
    $('img').each(function() {
      //work-around for ckeditor rendering of relative urls for images.
      if ($(this).attr("src").substr(0,8) == "http:///")
        $(this).attr('src', $(this).attr("src").substr(7));
      if ($(this).attr("src").substr(0,9) == "https:///")
        $(this).attr('src', $(this).attr("src").substr(8));
    });

    $('.is-dropdown-submenu-non-parent a').wrap('<span class="nolink">');

    //add class for field_gallery_images as needed
    $('.masonry-css div').addClass('masonry-css-item');
    $('#photo-finish div').addClass('columns').addClass('small-6').addClass('medium-4').addClass('large-3').addClass('gallery-item');
    $('#collection-gallery .masonry-css .masonry-css-item img').each(function(){
      $(this).parent().addClass('gallery-item');
    });
    $('#hof-collection-gallery .row div').addClass('column column-block');

    //Set destination URL for footer slider
    $('.destination-url').each(function(){
      $(this).parent().attr('data-location',$(this).text()).addClass('data-link');
    });

    //move link for thumbnail of gallery images that goes to content to the div as a data attribute
    $('.gallery').each(function(){
      var link = $(this).find('a');
      var href = $(this).find('a').attr('href');
      link.find('img').unwrap();
      $(this).addClass('data-link').attr('data-location',href);
    });

    //for slides and tiles that use a clickable div
    $('.data-link').click(function(){
      var loc = $(this).attr('data-location');
      if (loc.includes('http'))
        window.open(loc);
      else
        window.location = loc;
    });

    $(document).foundation();

    $('.lightbox-gallery').lightGallery({
      selector: '.gallery-item',
      share: false,
      download: false,
      thumbnail: false,
    });

    $('.exhibit-slides').slick({
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      customPaging: function(slick,index) {
        return '<a id="page-'+  (index + 1) +'"></a>';
      }
    });

    $('.footer-slides').slick({
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      customPaging: function(slick,index) {
        return '<a id="page-'+  (index + 1) +'"></a>';
      },
      responsive: [
        {
          breakpoint: 1122,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 748,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 374,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    if ($('.start-date').length > 0 && $('.end-date').length > 0 ){
      $('.end-date').before(" &#8211; ");
    }

    //initial click
    $('.slick-dots li').click();

    $('.bio-open').click(function() {
      $('.bio').show();
      $('.bio-open').hide();
      $("html, body").animate({ scrollTop:  $('.bio').offset().top -50 }, 500);
    });

    $('.bio-close').click(function() {
      $('.bio').hide();
      $('.bio-open').show();
      $("html, body").animate({ scrollTop:  $('.profile').offset().top -50 }, 500);
    });

    //adjust any images assigned to articles that are outside of the content it's self.
    $('.article').find('img').attr("align","left").addClass('article-image');
    $('.blog').find('img').attr("align","left").addClass('blog-image');

    //activate the nav area.  prevents blowout meu from appearing
    $('nav').show();

    //hide the link for webforms that take users back to the form on the confirmation page.  No way to do this specifically per page in drupal.
    if ($('.webform-confirmation')[0]){
      $('.links').css('display','none');
    }

    //add this class whenever the drupal embed class is used.
    $('.media_embed').addClass('responsive-embed').addClass('widescreen');
  });
}(jQuery));

jQuery(window).on('load', function ($) {
  Foundation.reInit('equalizer');
});
